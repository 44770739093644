import { setModuleSetting } from '../helper/constants';
import API from '../API/connection';
import API_PUBLIC from '../API/connection_public';
const setting = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    maxNotif: {},
    rangeTime: {},
    textNotif: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    successMessage: '',
    errorMessage: '',
  },
  actions: {
    async getSettingProfile({ commit }, payload) {
      try {
        commit('setLoading', true);
        const response = await API_PUBLIC.get('/profile', { params: payload });
        commit('setLoading', false);
        commit('setItem', response.data.result);
        return response.data.result;
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
        return error;
      }
    },
    async getSettingByModule({ commit }, payload) {
      try {
        commit('setLoading', true);
        const response = await API.get('/setting', { params: payload });
        commit('setLoading', false);
        commit(setModuleSetting[payload.module], response.data.result);
        return response.data.result;
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
        return error;
      }
    },
    async submitSetting({ commit }, payload) {
      commit('setSuccessMessage', null);
      commit('setErrorMessage', null);
      try {
        commit('setLoading', true);
        const response = await API.patch('/setting', payload);
        commit('setLoading', false);
        commit('setSuccessMessage', 'setting berhasil diperbarui');
        commit('setItem', response.data.result);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
        commit('setErrorMessage', 'setting gagal diperbarui');
        return error;
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setMaxNotif(state, item) {
      state.maxNotif = item;
    },
    setRangeTime(state, item) {
      state.rangeTime = item;
    },
    setTextNotif(state, item) {
      state.textNotif = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
  },
};

export default setting;
